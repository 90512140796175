<template>
  <PrimePage titleAlignment="left" :loading="pageIsLoading">
    <div class="prime-full-width-container prime-roster-container margin-top-twenty margin-bottom-twenty">
      <h3 class="pad-top-twenty">Programs</h3>
      <div v-for="programGroup in programData" :key="programGroup.programCategoryId">
        <h6 class="pad-top-thirty">{{ programGroup.programCategoryName }}</h6>
        <ion-card color="white" v-for="program in programGroup.programs" :key="program.id">
          <ion-card-content>
            <ion-grid>
              <ion-row class="ion-align-items-center pad-ten">
                <ion-col id="dashboardRowShortcut" class="name cursor-pointer text-bold" @click="goToNamedRoute('ProgramDashboard', program.id)">
                  {{ program.name }}
                </ion-col>
                <ion-col size="auto"></ion-col>
                <ion-col size="auto" class="pad-right-twenty">
                  <div class="info-label">Active Patients</div>
                  <div class="info-text">
                    {{ program.activePatientCount }}
                  </div>
                </ion-col>
                <ion-col size="auto" class="pad-right-twenty">
                  <div class="info-label">Open Tasks</div>
                  <div class="info-text">
                    {{ program.openTasksCount }}
                  </div>
                </ion-col>
                <ion-col size="auto" @click="goToNamedRoute('RosterProgram', program.id)">
                  <IconBulletList id="rosterShortcut" class="icon-medium icon-blue margin-lr-twenty cursor-pointer valign" v-tooltip="'Program Roster'" />
                </ion-col>
                <ion-col size="auto" @click="goToNamedRoute('OutreachesView', program.id)">
                  <IconOutreaches id="outreachShortcut" class="icon-medium icon-blue margin-lr-twenty cursor-pointer valign" v-tooltip="'Program Outreaches'" />
                </ion-col>
                <ion-col size="auto" @click="goToNamedRoute('ProgramDashboard', program.id)">
                  <IconCarrot id="dashboardShortcut" class="icon-medium icon-blue margin-lr-twenty cursor-pointer valign" />
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </PrimePage>
</template>
<script>
import PrimePage from "@/components/Global/PrimePage";
import { send as httpSend } from "@/services/Api";
import IconBulletList from "@/components/Global/Icons/IconBulletList";
import IconOutreaches from "@/components/Global/Icons/IconOutreaches";
import IconCarrot from "@/components/Global/Icons/IconCarrot";

export default {
  name: "ProgramsList",
  components: {
    PrimePage,
    IconBulletList,
    IconOutreaches,
    IconCarrot
  },
  data() {
    return {
      programData: undefined,
      pageIsLoading: false
    };
  },
  mounted() {
    this.pageIsLoading = true;
    this.getPrograms();
  },
  methods: {
    getPrograms() {
      const method = "get";
      const path = document.config.programsApi;
      this.pageIsLoading = true;

      httpSend({ path, method, authToken: true })
        .then(response => {
          let rtnArr = response.data;
          if (rtnArr.length > 0) {
            // remove the text triage program
            this.programData = rtnArr.filter(e => e.programCategoryId != 1);
          }
          this.pageIsLoading = false;
        })
        .catch(error => {
          this.pageIsLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load programs",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async sendToAddPatient() {
      this.$router.push({
        name: "ProgramAddPatients",
        params: { id: 3 }
      });
    },
    goToNamedRoute(routeName, programId) {
      this.$router.push({
        name: routeName,
        params: { program_id: programId }
      });
    }
  }
};
</script>
